<template>
  <div>
    <v-card-text class="pa-5 border-bottom">
      <validation-observer ref="observer">
        <v-row class="justify-center d-flex py-10">
          <v-col cols="12" align-center>
            <v-timeline class="px-2 py-8" :dense="$vuetify.breakpoint.smAndDown">
              <v-timeline-item v-for="(item, index) of [...historico].reverse()" :key="index">
                <v-card class="elevation-2 pa-4">
                  <v-card-title class="text-h6">
                    {{ item.conjunto }}
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <span>
                        Criado por:
                        <strong>{{ item.created_by }}</strong></span>
                    </div>
                    <div>
                      <span>
                        Início:
                        <strong>{{
                          item.created_at | toLocaleDateTimeString
                        }}</strong>
                      </span>
                    </div>
                    <div>
                      <span>
                        Finalizado por:
                        <strong>{{ item.finished_by }}</strong>
                      </span>
                    </div>
                    <div>
                      <span>
                        Fim:
                        <strong>
                          {{ item.finished_at | toLocaleDateTimeString }}
                        </strong>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="black darken-1" text @click="$emit('close')">Cancelar</v-btn>
    </v-card-actions>
  </div>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import { toLocaleDateTimeString } from '@/mixins/convertion'

export default {
  name: 'HistoricoVeiculo',
  props: {
    veiculo: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toLocaleDateTimeString(item) {
      if (item == null) return 'Ainda em uso'
      return toLocaleDateTimeString(item)
    }
  },
  data() {
    return {
      historico: [],
    }
  },

  async created() {
    this.historico = await this.getHistoricoSimple({ veiculo: this.veiculo })
  },
  methods: {
    ...mapActions('conjunto_historico', ['getHistoricoSimple']),
  }
}
</script>
