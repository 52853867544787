<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <input type="file" v-if="importacao" @change="onFileChange" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
          @change="handleGetVeiculo"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="veiculos"
        sort-by="ds_placa_cavalo"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Veiculos registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="() => handleGetVeiculo"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  @click="adicionarVeiculo"
                  >Novo</v-btn
                >
              </template>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.manufacturing_date`]="{ item }">
          {{ item.manufacturing_date | date2br }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="canUpdate"
            small
            class="mr-2"
            @click="$emit('editVeiculo', item)"
            >mdi-pencil</v-icon
          >
          <v-icon small v-if="canDelete" @click="prepareDeleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>

    <v-dialog v-if="canDelete" v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
            <p class="mt-8">Tem certeza que quer deletar esse veículo?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              dialogDeleteItem = false
              veiculoDelete = {}
            "
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deleteItem(veiculoDelete)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import UtilsApi from '@/services/utils/utils.api'
import PatioApi from '@/services/patio/index'
import readXlsxFile from 'read-excel-file'
import { date2br } from '@/mixins/convertion'

export default {
  data() {
    return {
      dialog: false,
      importacao: false,
      search: '',
      loading: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Motorista', value: 'motorista.user.name' },
        { text: 'Carroceria', value: 'carroceria.descricao' },
        { text: 'Modalidade', value: 'modalidade.descricao' },
        { text: 'Placa Cavalo', value: 'placa_cavalo' },
        { text: 'Placa Carreta 1', value: 'placa_carreta_1' },
        { text: 'Placa Carreta 2', value: 'placa_carreta_2' },
        { text: 'Placa Carreta 3', value: 'placa_carreta_3' },
        { text: 'Chassis', value: 'chassis' },
        { text: 'Renavam', value: 'renavam' },
        { text: 'Dt fabricação', value: 'manufacturing_date' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
      dialogDeleteItem: false,
      veiculoDelete: {},
    }
  },
  mixins: [date2br],
  computed: {
    ...mapState('patio', ['veiculos']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Veiculos.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo' : 'Editar'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      if (this.veiculos.length > 0) {
        this.veiculos.length = 0
      }
    }
  },

  methods: {
    ...mapActions('patio', [
      'getVeiculo',
      'addVeiculo',
      'editVeiculo',
      'deleteVeiculo',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    adicionarVeiculo() {
      this.$emit('showForm')
    },
    async handleGetVeiculo(search) {
      this.loading = true
      if (search.length >= 3) {
        await this.getVeiculo({
          placa_cavalo: search,
          owner: this.empresaAtual.public_id,
        })
      } else {
        alert('Digite pelo menos 3 letras da placa do cavalo e aperte TAB!')
      }
      this.loading = false
    },

    async onFileChange(event) {
      const start = new Date()
      this.loading = true
      console.log('Importando...')
      const estados = await UtilsApi.getEstadosBrasil()
      const carrocerias = await PatioApi.getCarrocerias()
      const modalidades = await PatioApi.getModalidades()
      const cores = await PatioApi.getCores()
      const marcas = await PatioApi.getMarcas()
      let xlsxfile = event.target.files ? event.target.files[0] : null
      await readXlsxFile(xlsxfile).then(async (rows) => {
        await Promise.all(
          rows.map(async (value) => {
            console.log(value[0] + ' ' + value[1] + ' ' + value[2])
            const placa_cavalo = value[0]
            const placa_carreta_1 = value[1]
            const placa_carreta_2 = value[2]
            const estado = estados.find((item) => item.sigla == 'BA').id
            const modalidade = modalidades.find(
              (item) => item.descricao == 'Truck' && item.eixo == 3
            ).id
            const carroceria = carrocerias.find(
              (item) => (item.descricao = 'Caçamba')
            ).id
            const tara = 15000
            const cor = cores.find((item) => (item.descricao = 'Azul')).id
            const marca = marcas.find((item) => (item.descricao = 'Volvo')).id
            const veiculo = await PatioApi.getVeiculos({
              placa_cavalo: placa_cavalo,
              owner: this.empresaAtual.public_id,
            })
            if (veiculo.length > 0 && veiculo[0]) {
              console.log('tem')
            } else {
              const veiculo = {
                proprietario: this.empresaAtual.public_id,
                placa_cavalo: placa_cavalo,
                placa_carreta_1: placa_carreta_1 || '',
                placa_carreta_2: placa_carreta_2 || '',
                estado: estado,
                modalidade: modalidade,
                carroceria: carroceria,
                tara: tara,
                cor: cor,
                marca: marca,
              }
              console.log({ ...veiculo })
              await this.submitAdicionarVeiculo(veiculo)
            }
          })
        )
      })
      this.loading = false
      const end = new Date()
      let duration = Math.abs(end - start)
      const hours = Math.floor(duration / 3600000)
      duration -= hours * 3600000
      const minutes = Math.floor(duration / 60000)
      duration -= minutes * 60000
      const seconds = Math.floor(duration / 1000)
      console.log('Duração da importação:')
      console.log(
        `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`
      )
    },
    async submitAdicionarVeiculo(veiculo) {
      try {
        const veiculo_novo = await PatioApi.addVeiculo(veiculo)
        console.log('Veiculo adicionado: ', veiculo_novo)
        this.successMessage(
          `Veiculo ${veiculo.placa_cavalo} adicionado com sucesso!`
        )
        this.$emit('addedVeiculo', veiculo_novo)
      } catch (err) {
        console.log(err)
        this.errorMessage(
          `Veiculo ${veiculo.placa_cavalo} não foi adicionado adicionado`
        )
        return
      }
    },

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.veiculoDelete = item
    },

    async deleteItem(item) {
      if (item.id) {
        await this.deleteVeiculo(item)
      } else {
        alert('Houve um problema. Tente novamente!')
        return false
      }
      this.dialogDeleteItem = false
      this.veiculoDelete = {}
      // const index = this.desserts.indexOf(item)
      // confirm('Are you sure you want to delete this item?') &&
      //   this.desserts.splice(index, 1)
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
  },
}
</script>
