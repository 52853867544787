<template>
  <div>
    <v-card-text class="pa-5 border-bottom">
      <validation-observer ref="observer">
        <v-row class="justify-center d-flex py-10">
          <v-data-table :headers="headers" :items="conjuntosList" sort-by="descricao" class="border"
            :item-class="itemRowBackground">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Conjuntos adicionados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on">Novo Conjunto</v-btn>
                  </template>
                  <conjunto ref="modal_conjunto" :veiculo="veiculo" @adicionarConjunto="adicionarConjunto"
                    @close="closeConjunto" />
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mx-2" @click="utilizaConjunto(item)"
                v-if="!(veiculo.conjunto_atual == item.public_id)">mdi-car-connected</v-icon>
              <v-icon small class="mx-2" @click="verConjunto(item)" v-if="item.public_id">mdi-eye</v-icon>
              <v-icon small @click="excluirConjunto(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <span>Sem Conjunto adicionados</span>
            </template>
          </v-data-table>
        </v-row>
      </validation-observer>
    </v-card-text>
    <v-card-actions v-if="hasButtonSave">
      <v-spacer></v-spacer>
      <buttons-cadastro @close="$emit('close')" @save="save" />
    </v-card-actions>
  </div>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import Conjunto from './Conjunto.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  name: 'EtapaConjunto',
  props: {
    value: Array,
    veiculo: {
      type: Object,
      required: true,
    },
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ButtonsCadastro, Conjunto },
  data() {
    return {
      dialog: false,
      conjunto: null,
      conjuntos: [],
      conjuntosList: this.value,
      headers: [
        {
          text: 'public_id',
          align: 'start',
          value: 'public_id',
        },
        {
          text: 'Carreta 1',
          align: 'start',
          value: 'carreta_1',
        },
        {
          text: 'Carreta 2',
          align: 'start',
          value: 'carreta_2',
        },
        {
          text: 'Carreta 3',
          align: 'start',
          value: 'carreta_3',
        },
        {
          text: 'Dolly',
          align: 'start',
          value: 'dolly',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.conjuntosList, val)
      this.$forceUpdate()
    },
    conjuntosList(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('conjunto', ['addConjunto', 'retrieveConjunto', 'deleteConjunto', 'utilizarConjunto']),
    closeConjunto() {
      this.dialog = false
      this.conjunto = null
    },
    close() {
      this.$emit('close')
    },
    async utilizaConjunto(item) {
      try {
        const data = await this.utilizarConjunto({ veiculo: this.veiculo, conjunto: item })
        this.$emit("utilizaConjunto", item, data)
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async verConjunto(item) {
      try {
        this.dialog = true
        const conjunto = await this.retrieveConjunto({ veiculo: this.veiculo, conjunto: item })
        this.$nextTick(() => {
          this.$refs.modal_conjunto.showItem(conjunto)
        })
      } catch (error) {
        this.dialog = false
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async adicionarConjunto(item) {
      try {
        const conjunto = await this.save(item)
        this.conjuntosList.push(conjunto)
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async save(item) {
      try {
        const conjunto = await this.addConjunto({ veiculo: this.veiculo, conjunto: item })
        return conjunto
      }
      catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async excluirConjunto(conjunto) {
      const i = this.conjuntosList.findIndex((item) => item == conjunto)
      if (i >= 0) {
        if (conjunto.public_id) {
          await this.deleteConjunto({ veiculo: this.veiculo, conjunto })
        }
        this.conjuntosList.splice(i, 1)
      }
    },
    itemRowBackground(item) {
      return (this.veiculo.conjunto_atual == item.public_id) ? 'status-atual' : ''
    },
  }
}
</script>
<style>
.status-atual {
  background-color: #00aa9e;
  /* background-color: #23b14d; */
  color: white;
}

.status-atual:hover {
  background-color: #00aa80 !important;
  /* background-color: #23b14d; */
  color: white !important;
  ;
}
</style>
