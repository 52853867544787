<template>
  <div>
    <v-timeline class="px-2 py-8" :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item v-for="(item, index) of [...historico].reverse()" :key="index">
        <v-card class="elevation-2 pa-4">
          <v-card-text>
            <div>
              <span>
                Criado por:
                <strong>{{ item.created_by }}</strong></span>
            </div>
            <div>
              <span>
                Início:
                <strong>{{
                  item.created_at | toLocaleDateTimeString
                }}</strong>
              </span>
            </div>
            <div>
              <span>
                Finalizado por:
                <strong>{{ item.finished_by }}</strong>
              </span>
            </div>
            <div>
              <span>
                Fim:
                <strong>
                  {{ item.finished_at | toLocaleDateTimeString }}
                </strong>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
import { customChange } from '@/mixins/autocomplete.js'
import { toLocaleDateTimeString } from '@/mixins/convertion'

export default {
  mixins: [customChange],
  props: {
    historico: {
      type: Array,
      required: true,
    },
  },
  filters: {
    toLocaleDateTimeString(item) {
      if (item == null) return 'Ainda em uso'
      return toLocaleDateTimeString(item)
    }
  },
}
</script>

<style>
.v-timeline-item:last-child {
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped >
.v-card {
  .v-card__text {
    padding-bottom: 0;
  }
}
</style>

