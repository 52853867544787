<template>
  <div>
    <validation-observer ref="observer">
      <v-tabs icons-and-text v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#dados">
          Dados gerais <v-icon>mdi-home-city</v-icon></v-tab>
        <v-tab href="#documentos">
          Documentos <v-icon>mdi-format-list-bulleted-square</v-icon></v-tab>
        <v-tab href="#rfids"> RFIDs <v-icon>mdi-barcode-scan</v-icon></v-tab>
        <v-tab href="#conjuntos"> Conjuntos <v-icon>mdi-truck-plus-outline</v-icon></v-tab>
        <v-tab href="#historico"> Historico <v-icon>mdi-truck-flatbed</v-icon></v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="dados">
            <etapa-dados-veiculo :value="veiculo" hasButtonSave @close="close" @save="save" />
          </v-tab-item>
          <v-tab-item value="documentos">
            <etapa-documentos ref="etapaDocumentos" hasButtonSave @close="close" @updateDocs="updateDocs"
              v-model="veiculo.documentos" />
          </v-tab-item>
          <v-tab-item value="rfids">
            <etapa-r-f-i-d hasButtonSave ref="etapaRFID" v-model="veiculo.rfids" :veiculo="veiculo" @close="close" />
          </v-tab-item>
          <v-tab-item value="conjuntos">
            <etapa-conjunto hasButtonSave ref="etapaConjunto" @utilizaConjunto="utilizaConjunto"
              v-model="veiculo.conjuntos" :veiculo="veiculo" @close="close" />
          </v-tab-item>
          <v-tab-item value="historico">
            <historico-veiculo hasButtonSave ref="etapaHistorico" :veiculo="veiculo" @close="close" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import EtapaDadosVeiculo from './etapas/EtapaDadosVeiculo'
import EtapaDocumentos from './etapas/EtapaDocumentos'
import EtapaRFID from './etapas/EtapaRFID'
import EtapaConjunto from './etapas/EtapaConjunto'
import HistoricoVeiculo from './etapas/HistoricoVeiculo'

export default {
  name: 'EditarVeiculo',

  props: {
    veiculo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: '',
      hasButtonSave: true,
    }
  },
  components: { EtapaDadosVeiculo, EtapaDocumentos, EtapaRFID, EtapaConjunto, HistoricoVeiculo, },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  methods: {
    ...mapActions('patio', ['editVeiculo', 'updateDocumentosVeiculo']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    close: function () {
      this.$emit('close')
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    utilizaConjunto(conjunto, utilizacao) {
      this.$emit("utilizaConjunto", conjunto, utilizacao)
    },
    save: async function () {
      if (this.validate()) {
        try {
          let veiculoAdded = await this.editVeiculo({
            ...this.veiculo,
            documentos: this.veiculo.documentos.map(
              (item) => item.attachment_key || item
            ),
            proprietario: this.empresaAtual.public_id,
          })
          this.$emit('addedVeiculo', veiculoAdded)
          this.successMessage('Veículo atualizado com sucesso!')

          await this.$refs.etapaRFID.save()
          // this.veiculo = {}
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async updateDocs(listaDocs) {
      try {
        await this.updateDocumentosVeiculo({
          veiculo: this.veiculo,
          documentos: listaDocs,
        })
        this.$emit('close')
        this.successMessage(
          `Veiculo ${this.veiculo.placa_cavalo} editado com sucesso`
        )
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  },
}
</script>

<style scopped lang="scss">
.preview2 {
  margin: auto;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  >div,
  >img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: auto;
    border: 3px solid #ccc;
  }

  >div {
    border: 3px solid #ccc;
    background: #ededed;
  }
}

.image-preview {
  background-color: #ccc;
}
</style>
