<template>
  <v-container fluid>
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Veiculos">
          <cadastro-veiculo v-if="showForm" :veiculoEdit="veiculoEdit" @addedVeiculo="
            showForm = false
          veiculoEdit = {}
            " />
          <editar-veiculo v-else-if="edit" @close="
            edit = false
          veiculoEdit = {}
            " :veiculo="veiculoEdit" @utilizaConjunto="utilizaConjunto" />
          <listar-veiculo v-else @showForm="showForm = true" @editVeiculo="editVeiculo" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarVeiculo from '@/components/patio/veiculos/Veiculo/ListarVeiculo'
import CadastroVeiculo from '@/components/patio/veiculos/Veiculo/CadastroVeiculo'
import EditarVeiculo from '@/components/patio/veiculos/Veiculo/EditarVeiculo'

import { mapActions } from 'vuex'

export default {
  data() {
    return {
      showForm: false,
      edit: false,
      veiculoEdit: {},
      page: {
        title: 'Gerenciamento de Veiculo',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Veiculos',
          disabled: false,
        },
      ],
    }
  },
  components: {
    CadastroVeiculo,
    ListarVeiculo,
    EditarVeiculo,
  },
  methods: {
    ...mapActions('patio', ['getRFIDs']),
    utilizaConjunto(conjunto, utilizacao) {
      console.log(this.veiculoEdit)
      if (conjunto.historico) {
        conjunto.historico.push(utilizacao)
      } else {
        conjunto.historico = [utilizacao]
      }
      this.veiculoEdit.conjunto_atual = conjunto.public_id
      console.log(conjunto, utilizacao)
    },
    async editVeiculo(veiculo) {
      this.veiculoEdit = veiculo
      const rfids = await this.getRFIDs({ veiculo })
      this.veiculoEdit.rfids = rfids
      this.edit = true
    },
  },
}
</script>
