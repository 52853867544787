var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),(_vm.importacao)?_c('input',{attrs:{"type":"file"},on:{"change":_vm.onFileChange}}):_vm._e(),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},on:{"change":_vm.handleGetVeiculo},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.veiculos,"sort-by":"ds_placa_cavalo"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Veiculos registrados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":function () { return _vm.handleGetVeiculo; }}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.adicionarVeiculo}},on),[_vm._v("Novo")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)]},proxy:true},{key:"item.manufacturing_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date2br")(item.manufacturing_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('editVeiculo', item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.prepareDeleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1),(_vm.canDelete)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDeleteItem),callback:function ($$v) {_vm.dialogDeleteItem=$$v},expression:"dialogDeleteItem"}},[_c('v-card',[_c('v-card-title',[_c('v-card-text',[_c('h3',{staticClass:"transition-swing text-h3 mb--1 error--text"},[_vm._v("Cuidado!")]),_c('p',{staticClass:"mt-8"},[_vm._v("Tem certeza que quer deletar esse veículo?")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDeleteItem = false
            _vm.veiculoDelete = {}}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.veiculoDelete)}}},[_vm._v(" Confirmar ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }