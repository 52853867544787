<template>
  <v-card>
    <v-card-title>
      <div class="d-flex flex-column align-start">
        <span class="headline">{{ formTitle }}</span>
        <span class="text-caption">{{
          editedItem.public_id || 'Não criada'
        }}</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="close"> Fechar </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="editedItem.carreta_1" :items="implementoList" item-text="placa"
              item-value="public_id" return-object label="Carreta 1" :readonly="show"
              @keyup.native="customChange($event, handleGetImplementos)" :loading="calling"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="editedItem.carreta_2" :items="implementoList" item-text="placa"
              item-value="public_id" return-object label="Carreta 2" :readonly="show"
              @keyup.native="customChange($event, handleGetImplementos)" :loading="calling"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="editedItem.carreta_3" :items="implementoList" item-text="placa"
              item-value="public_id" return-object label="Carreta 3" :readonly="show"
              @keyup.native="customChange($event, handleGetImplementos)" :loading="calling"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="editedItem.dolly" :items="implementoList" item-text="placa" item-value="public_id"
              return-object label="Dolly" :readonly="show" @keyup.native="customChange($event, handleGetImplementos)"
              :loading="calling"></v-autocomplete>
          </v-col>
        </v-row>
        <div v-if="show">
          <v-row>
            <v-col cols="12" align-center>
              <Historico :historico="editedItem.historico" />
              <!-- <Historico @handleGetAgendamentos="handleGetAgendamentos" @handleGetConjuntos="handleGetConjuntos" /> -->
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
      <v-btn color="blue darken-1" text @click="save" v-if="!show">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { customChange } from '@/mixins/autocomplete.js'
import Historico from './Historico.vue'
// import Historico from '../historico/Component.vue'

export default {
  mixins: [customChange],
  components: { Historico },
  props: {
    veiculo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      editedItem: {
        public_id: null,
        veiculo: null,
        carreta_1: null,
        carreta_2: null,
        carreta_3: null,
        dolly: null,
      },
      defaultItem: {
        public_id: null,
        veiculo: null,
        carreta_1: null,
        carreta_2: null,
        carreta_3: null,
        dolly: null,
      },
      implementos: [],
    }
  },
  computed: {
    ...mapState('patio', []),
    ...mapState('auth', ['empresaAtual']),
    implementoList() {
      return [
        this.editedItem.carreta_1,
        this.editedItem.carreta_2,
        this.editedItem.carreta_3,
        this.editedItem.dolly,
      ].concat(this.implementos)
        .filter((item) => item)
    },
    formTitle() {
      return this.show ? `Histórico do Conjunto (${this.veiculo?.placa_cavalo})` : `Adicionar conjunto (${this.veiculo?.placa_cavalo})`
    },
  },

  watch: {},

  async created() { this.editedItem.veiculo = this.veiculo },

  methods: {
    ...mapActions('implemento', [
      'getImplementos',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    showItem(item) {
      this.show = true
      this.editedItem = item
    },
    async handleGetAgendamentos(params) {
      console.log(params)
    },
    async handleGetConjuntos(params) {
      console.log(params)
    },
    async handleGetImplementos(event) {
      if (this.veiculo.proprietario != this.empresaAtual.public_id) {
        this.errorMessage("Empresa atual não é proprietária do veículo!")
      }
      const value = event.target.value
      const { results } = await this.getImplementos({
        placa: value,
        proprietario: { public_id: this.veiculo.proprietario },
      },)
      this.implementos = results
    },

    close() {
      this.editedItem = {}
      this.$nextTick(() => {
        Object.assign(this.editedItem, this.defaultItem)
      })
      this.$emit('close')
    },

    save() {
      this.$emit('adicionarConjunto', { ...this.editedItem })
      this.close()
    },
  },
}
</script>
